<template>
  <div class="container">
    <BaseHeader title="Estratégico" :navigation="navigation"> </BaseHeader>
    <div class="mt-4">
      <div class="grid-estrategico">
        <div class="item" v-if="level != 'gerent_seller'" :class="can_list ? '' : 'blocked'">
          <div class="header">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 13.75L9.5 18L18 13.75M1 9.5L9.5 13.75L18 9.5M9.5 1L1 5.25L9.5 9.5L18 5.25L9.5 1Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p>Plano estratégico</p>
          </div>
          <div class="text">
            Gerencie suas simulações, planos e investimentos.
          </div>
          <div class="footer" @click="can_list ? $router.push({ name: 'PlanoLista' }) : ''">
            <div class="access">
              <p>Acessar</p>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6H11M11 6L6 1M11 6L6 11"
                  stroke="#4ea934"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="item" v-if="level != 'gerent_seller'">
          <div class="header">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.5 14.6C12.3167 14.6 14.6 12.3167 14.6 9.5C14.6 6.68335 12.3167 4.4 9.5 4.4C6.68335 4.4 4.4 6.68335 4.4 9.5C4.4 12.3167 6.68335 14.6 9.5 14.6Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.5 11.2C10.4389 11.2 11.2 10.4389 11.2 9.5C11.2 8.56112 10.4389 7.8 9.5 7.8C8.56112 7.8 7.8 8.56112 7.8 9.5C7.8 10.4389 8.56112 11.2 9.5 11.2Z"
                stroke="#4ea934"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p>Gestão de tráfego</p>
          </div>
          <div class="text">
            Informe seus investimentos de suas campanhas para metrificarmos.
          </div>
          <div class="footer" @click="$router.push({ name: 'Trafego' })">
            <div class="access">
              <p>Acessar</p>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6H11M11 6L6 1M11 6L6 11"
                  stroke="#4ea934"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="item"
          :class="$store.getters.recursos.ncrm ? '' : 'blocked'"
          v-if="
            level !== 'templater' &&
            level !== 'blogger'
          "
        >
          <div class="header">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#4ea934"
              viewBox="0 0 256 256"
            >
              <path
                d="M140,80v41.21l34.17,20.5a12,12,0,1,1-12.34,20.58l-40-24A12,12,0,0,1,116,128V80a12,12,0,0,1,24,0ZM128,28A99.38,99.38,0,0,0,57.24,57.34c-4.69,4.74-9,9.37-13.24,14V64a12,12,0,0,0-24,0v40a12,12,0,0,0,12,12H72a12,12,0,0,0,0-24H57.77C63,86,68.37,80.22,74.26,74.26a76,76,0,1,1,1.58,109,12,12,0,0,0-16.48,17.46A100,100,0,1,0,128,28Z"
              ></path>
            </svg>

            <p>Histórico de CRM</p>
          </div>
          <div class="text">
            Acompanhe as movimentações e a quantidade de leads do CRM.
          </div>
          <div class="footer" @click="$store.getters.recursos.ncrm ? $router.push({ name: 'relatorio-crm' }) : ''">
            <div class="access">
              <p>Acessar</p>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6H11M11 6L6 1M11 6L6 11"
                  stroke="#4ea934"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
  
        
        <div
          class="item"
          :class="$store.getters.recursos.nhistory ? '' : 'blocked'"
          v-if="
            $store.getters.recursos.nhistory &&
            $store.getters.user.user.level == 'owner' ||
            $store.getters.user.user.level == 'admin' ||
            $store.getters.user.user.level == 'commercial_leader'
          "
        >
          <div class="header">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#4ea934"
              viewBox="0 0 256 256"
            >
              <path
                d="M216,76H188V48a20,20,0,0,0-20-20H40A20,20,0,0,0,20,48V176a12,12,0,0,0,19.54,9.33l28.46-23V184a20,20,0,0,0,20,20h92.17l36.29,29.33A12,12,0,0,0,236,224V96A20,20,0,0,0,216,76ZM44,150.87V52H164v80H71.58A12,12,0,0,0,64,134.67Zm168,48-20-16.2a12,12,0,0,0-7.54-2.67H92V156h76a20,20,0,0,0,20-20V100h24Z"
              ></path>
            </svg>

            <p>Histórico de mensagens</p>
          </div>
          <div class="text">Visualize as mensagens de seus vendedores.</div>

          <div class="footer">
            <component :is="$store.getters.recursos.nhistory ? 'router-link' : 'div'" :to="$store.getters.recursos.nhistory ? 'estrategico/log-messages' : ''">
              <div class="access">
                <p>Acessar</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6H11M11 6L6 1M11 6L6 11"
                    stroke="#4ea934"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </component>
          </div>
        </div>

        <div
          class="item"
          v-if="
            level !== 'templater' &&
            level !== 'blogger' &&
            this.$store.getters.recursos.ncrm > 0
            && accessTagManagement
          "
        >
          <div class="header">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              fill="#4ea934" 
              viewBox="0 0 256 256">
              <path 
                d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0v94.37L90.73,98a8,8,0,0,1,10.07-.38l58.81,44.11L218.73,90a8,8,0,1,1,10.54,12l-64,56a8,8,0,0,1-10.07.38L96.39,114.29,40,163.63V200H224A8,8,0,0,1,232,208Z">
              </path>
            </svg>

            <p>Relatório de tags</p>
          </div>
          <div class="text">
            Gerencie e extraia relatórios de tags da sua base
          </div>
          <div class="footer" @click="$router.push({ name: 'relatorio-tag-lista' })">
            <div class="access">
              <p>Acessar</p>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6H11M11 6L6 1M11 6L6 11"
                  stroke="#4ea934"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <ModalAutomocao />
    </div>
  </div>
</template>
<script>
// AUTOMAÇÂO DE VENDEDOR
import ModalAutomocao from "@/components/Apps/AutomocaoVendedor/ModalAutomocao";
import Vue from "vue";

import TagService from "@/services/resources/TagService";
const serviceTag = TagService.build();

import BaseHeader from "@/components/BaseHeader";
//
export default {
  name: "ListaMidias",
  components: {
    BaseHeader,
    ModalAutomocao,
  },
  data() {
    return {
      navigation: [{ link: "Estratégico", to: this.$route.fullPath }],
      client: {
        width: 0,
      },
      accessTagManagement: false,
      level: this.$store.getters.user.user.level,
      can_list: false,
    };
  },
  methods: {
    validSimulator() {
      this.$router.push({
        name: "Plano2",
      });
      // Vue.swal({
      //   title: "Simulador",
      //   text: `Você deseja acessar qual tipo de simulador ?`,
      //   // type: "danger",
      //   showCancelButton: true,
      //   confirmButtonText: "Lucro",
      //   cancelButtonText: "Investimento",
      //   customClass: "sweet-container",
      //   confirmButtonClass: "button button-black mt-3 mb-3",
      //   cancelButtonClass: "button button-link-primary mr-4 mt-3 mb-3",
      //   buttonsStyling: false,
      //   reverseButtons: true,
      // }).then((result) => {
      //   if (result.dismiss == "esc" || result.dismiss == "backdrop") {
      //     return;
      //   }
      //   if (!result.isConfirmed) {
      //     this.$router.push({
      //       name: "Plano",
      //     });
      //   }
      //   if (result.isConfirmed) {
      //     this.$router.push({
      //       name: "Plano2",
      //     });
      //   }
      // });
    },
    openModal(data, fluxo) {
      this.$bvModal.show(data);
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    confirmGWhats() {
      Vue.swal({
        title: "G Whats",
        text: `Você deseja realizar o download do aplicativo do G Whats em seu dispositivo?`,
        // type: "danger",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const url = "https://gdigital.com.br/app-/gwhats.php";
          window.open(url, "_blank");
        }
      });
    },
    canListPlans() {
      if (this.$store.getters.recursos.nstrategic) {
        this.can_list = true;
      }
    },
    canUseTagManagement() {
      serviceTag.read({id: `/management/access`}).then((r) => {
        this.accessTagManagement =  true;
      }).catch(err => {
        this.accessTagManagement = false;
      });
    } 
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.canListPlans();
    this.canUseTagManagement()
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
};
</script>

<style lang="scss" scoped>
.blocked {
  position: relative;
  background-color: var(--white-light);
  cursor: not-allowed;
  user-select: none;
  -webkit-user-drag: none;
  *{
    cursor: not-allowed !important;
    user-select: none;
    -webkit-user-drag: none;
  }
  &::before {
    position: absolute;
    top: 15px;
    right: -10px;
    width: 35px;
    height: 35px;
    background-color: var(--greenn);
    content: '';
    background-image: url("../../assets/icons/lock.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 5px 0 10px;
    font-size: 13px;
  }
  &::after {
    content: '';
    background-color: var(--greenn-dark);
    width: 10px;
    height: 10px;
    clip-path: polygon(0% 0%, 100% 0%, 0 100%);
    position: absolute;
    top: 50px;
    right: -10px;
  }
}
.grid-estrategico {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  .item {
    width: 100%;
    border: var(--white-medium) solid 1px;
    border-radius: 10px;
    padding: 25px 20px;

    .footer {
      border-top: var(--white-medium) solid 1px;
      padding-top: 10px;
      cursor: pointer;

      .access {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding-top: 10px;

        p {
          margin: 0;
          color: var(--gray05);
        }
      }
    }

    .text {
      color: var(--gray05);
      margin-bottom: 15px;
    }

    .header {
      display: grid;
      grid-template-columns: 30px 1fr;
      gap: 20px;
      margin-bottom: 15px;

      p {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 65px;
  }
}

a {
  text-decoration: none;
}

.apps-h1 {
  text-align: center;
  margin: 30px 0;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 5px;
}

.apps-logo {
  width: 100%;
  height: 100px;
  transform: scale(5);
}

.container-table {
  display: flex !important;
  justify-content: center !important;
}

.apps-title {
  position: relative;
  top: 1rem;
  font-size: 14px;
  color: #81858e;
  letter-spacing: 3px;
  // white-space: nowrap;
}

.card-apps {
  overflow: hidden;
  display: grid;
  justify-content: center;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  padding: 45px;
  cursor: pointer;
  transition: all 0.2s;
  background: #f7f7f7;

  &:hover {
    background: #e7e7e7a2;
  }
}

.apps {
  text-align: center;
  position: relative;
}

.container-appss {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

.container-apps {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 45px;
}

@media screen and (max-width: 880px) {
  .card-apps {
    padding: 0;
    clip-path: border-box;
  }

  .container-appss {
    display: grid;
    grid-template-columns: 1fr;
    gap: 45px;
  }

  .container-apps {
    justify-content: initial;
  }
}
</style>
