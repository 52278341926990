<template>
  <div>
    <ListaEstrategicos></ListaEstrategicos>
  </div>

</template>

<script>
import BaseView from "@/template/BaseView.vue";
import ListaEstrategicos from "@/components/Apps/ListaEstrategicos.vue";

export default {
  components: {
    BaseView,
    ListaEstrategicos,
  },
  created() {
    if (this.$store.getters.user.user.level == 'blogger' || this.$store.getters.user.user.level == 'seller' || this.$store.getters.recursos.nstrategic == 0) {this.$router.push("/dynamicRoute/AccessDenied")};
  },
};
</script>
